<template>
  <b-container fluid class="mb-6 mt-6 p-0">
    <b-row>
      <b-col>
        <div class="card card-custom gutter-b">
          <div class="mt-0">
            <template-table
              :column="column"
              :data="data"
              :paging="paging"
              :tableAction="false"
              :selectAction="false"
              :searchAction="false"
            >
              <template v-slot:body="{ item }">
                <td>
                  <span class="font-weight-bolder">
                    {{
                      $moment(item.date * 1000).format('HH:mm, DD/MM/YYYY')
                    }}</span
                  >
                </td>
                <td>
                  <span
                    class="font-weight-bolder"
                    :style="`color : ${item.bmiColorCode}`"
                  >
                    {{ item.bmi }}
                  </span>
                </td>
                <td>
                  <span
                    class="font-weight-bolder"
                    :style="`color : ${item.bmiColorCode}`"
                  >
                    {{ item.weight }}
                  </span>
                  <span class="unit">kg</span>
                </td>
                <td>
                  <span
                    class="font-weight-bolder"
                    :style="`color : ${item.waistColorCode}`"
                  >
                    {{ item.waist }}
                  </span>
                  <span class="unit">cm</span>
                </td>
                <td>
                  <span class="font-weight-bolder">
                    {{ item.height }}
                  </span>
                  <span class="unit">cm</span>
                </td>
                <td>
                  <span class="font-weight-bolder"> {{ item.note }}</span>
                </td>
                <td>
                  <div class="d-flex">
                    <div
                      class="preview"
                      v-for="(image, index) in item.images"
                      :key="index"
                    >
                      <img
                        @click.stop="handlePreview(image.url)"
                        :src="image.url"
                        class="preview-image"
                        alt=""
                      />
                    </div>
                  </div>
                </td>
              </template>
            </template-table>
          </div>
          <image-preview :image.sync="imagePreview" />
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: 'WeighTable',
  components: {
    'image-preview': () => import('../components/ModalPreview.vue'),
  },
  props: {
    time: {
      type: Object,
    },
    idUser: {
      type: [String, Number],
    },
  },
  data() {
    return {
      column: [
        {
          key: 'date',
          label: 'Ngày nhập',
          sortable: false,
        },
        {
          key: 'bmi',
          label: 'BMI',
          sortable: false,
        },
        {
          key: 'weight',
          label: 'Cân nặng',
          sortable: false,
        },
        {
          key: 'waist',
          label: 'Vòng eo',
          sortable: false,
        },
        {
          key: 'height',
          label: 'Chiều cao',
          sortable: false,
        },

        {
          key: 'note',
          label: 'Ghi chú',
          sortable: false,
        },
        {
          key: 'thumbnail',
          label: 'Hình ảnh',
          sortable: false,
        },
      ],
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      searchParams: {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        periodFilterType: 1,
      },
      imagePreview: null,
    };
  },
  computed: {
    id() {
      return this.idUser;
    },
  },
  watch: {
    paging: {
      handler(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
      deep: true,
    },
    time: {
      handler(newVal) {
        this.searchParams.periodFilterType = newVal.value;
        this.loadData();
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    handlePreview(image) {
      this.imagePreview = image;
      this.$bvModal.show('image-preview');
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('Admin/Weight/Input', {
          params: {
            ...this.searchParams,
            ...this.paging,
            size: this.paging.pageSize,
            patientId: this.id,
          },
        })
        .then(({ meta, data }) => {
          this.data = data || [];

          this.paging.total = meta.total;
        })
        .catch((error) => {
          this.$toastr.e({
            title: 'Lỗi',
            msg: error,
          });
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  color: #b5b5c3;
}
.preview {
  cursor: pointer;
  width: 40px;
  height: 40px;
}
.preview:not(:first-child) {
  margin-left: 10px;
}
.preview-image {
  border-radius: 6px;
  display: block;
  width: 100%;
  object-fit: cover;
  height: 100%;
}
</style>
